<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >

              <!-- Name -->
              <v-text-field
                v-model="name"
                label="Name"
                outlined
                dense
                persistent-hint
              ></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
      </div> 

      <div class="px-3">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >

              <!-- email address -->
              <v-text-field
                v-model="email"
                label="Email Address"
                outlined
                dense
                persistent-hint
              ></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
      </div>
 

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!-- action buttons -->
        <v-card-text>
          <v-btn
           :disabled="isProcessing"
            color="primary"
            class="me-3 mt-3"
            @click="createAdmin"
          >
            Create Admin Account
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { newAdmin } from '../../../api/user'

export default {
  data() {
    return {
      email: '',
      name: '',
      isProcessing: false,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  methods:{
      async createAdmin(){
        
        try{
            this.isProcessing = true
            await newAdmin({email: this.email, name: this.name})
            this.$toast.open({
                message:'New admin added. A welcome email will be sent to them soon.',
                type:'success',
            });
            this.email = ''
            this.name = ''
        }
        catch(e){
            if(e.code === 'ERR_BAD_REQUEST'){
                this.$toast.open({
                message: e.response.data.details || e.response.data.error.message,
                    type: 'error',
                });
            }
            else{
                this.$toast.open({
                    message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                    type: 'error',
                });
            }
        }

        this.isProcessing =false
      }
  }
}
</script>
